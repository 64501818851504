/* 1. Testimonial css
   2. Slider css
   3. Arrows
   4. Dots  */

/* ----------------------------------
	Testimonial Section - Styles
 ------------------------------------ */
 .testimonail-wrap {
    background-image: -moz-linear-gradient(0deg, #34b5bf 0%, #210c59 100%);
    background-image: -webkit-linear-gradient(0deg, #34b5bf 0%, #210c59 100%);
    background-image: linear-gradient(0deg, #34b5bf 0%, #210c59 100%);
    position: relative;
    z-index: 1; 
    padding-bottom: 125px;
  }
    .testimonail-wrap .section-title h2, .testimonail-wrap .section-title p {
      color: #ffffff;
    } 
    .testimonail-wrap .testimonial-bg {
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      width: 100%;
      height: 100%;
      z-index: -1; }
    .testimonail-wrap .testimonial-list {
      padding-top: 20px;
      min-height: 443px; }
      .testimonail-wrap .testimonial-list .slick-dots {
        position: relative;
      }
     .testimonial-list .slick-dots .slick-dots li button {
        background: #fa5788a6 !important;
      }
    .testimonail-wrap .single-testimonial {
      padding: 0px 30px 40px;
      text-align: center;
      position: relative;
      background-repeat: no-repeat;
      background-size: cover;
      margin: 40px 0px 50px;
      background-color: #fff;
      border-radius: 10px;
      -webkit-transition: -webkit-transform 0.5s ease-in-out;
      transition: -webkit-transform 0.5s ease-in-out;
      -o-transition: transform 0.5s ease-in-out;
      transition: transform 0.5s ease-in-out;
      transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out; }
      .testimonail-wrap .single-testimonial .client-img {
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 80px;
        height: 80px;
        overflow: hidden;
        border-radius: 10%;
        margin: 0 auto;
        background-color: #cac7d7;
        -webkit-box-shadow: 0px 0px 15px 0px rgba(42, 35, 81, 0.2);
        box-shadow: 0px 0px 15px 0px rgba(42, 35, 81, 0.2); }
      .testimonail-wrap .single-testimonial .client-info {
        margin-bottom: 15px; }
        .testimonail-wrap .single-testimonial .client-info p span {
          color: #22dd73; }
      .testimonail-wrap .single-testimonial p {
        font-size: 12px;
        line-height: 1.8; }
  
  .testimonail-wrap .single-testimonial::before, .testimonail-wrap .single-testimonial::after {
    left: 0;
    width: 80%;
    content: "";
    z-index: -2;
    height: 52px;
    bottom: -35px;
    position: absolute;
    border-radius: 10px;
    background: #ffffff52;
    -webkit-transform: translateX(12%);
    -ms-transform: translateX(12%);
    transform: translateX(12%);
    -webkit-box-shadow: 0px 0px 25px 0px rgba(20, 27, 201, 0.05);
    box-shadow: 0px 0px 25px 0px rgba(20, 27, 201, 0.05); }
  
  .testimonail-wrap .single-testimonial::before {
    width: 90%;
    bottom: -20px;
    -webkit-transform: translateX(5%);
    -ms-transform: translateX(5%);
    transform: translateX(5%);
    background-color: #ffffffb0;
    -webkit-box-shadow: 0px 0px 25px 0px rgba(20, 27, 201, 0.05);
    box-shadow: 0px 0px 25px 0px rgba(20, 27, 201, 0.05);
    z-index: -1; }
  
  .testimonail-wrap .testimonial-list .slick-slide:not(.slick-current) {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8); }
  
  .single-testimonial.slick-slide.slick-current.slick-active.slick-center {
    opacity: 1; }
    .single-testimonial.slick-slide.slick-current.slick-active.slick-center p {
      font-size: 14px;
      line-height: 28px; }



/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}


@charset 'UTF-8';
.slick-loading .slick-list
{
    background: #fff center center no-repeat;
}


/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: -25px;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
    content: '←';
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
}

.slick-next
{
    right: -25px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    content: '→';
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}


/*...............
    Dots 
.................*/

.slick-dots
{
    position: absolute;
    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}

