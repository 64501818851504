/*
=================================
|***    Table of contents:   ***|
=================================

1. General styles
2. Typography
3. Helpers
4. Preloader
5. Go up button
6. Header and navigation
7. Hero Section / Hero Slider
8. About us / Features
9. Service
10. Price
11. Testimonial
12. Map
13. Content Block
14. Faq Ask
15. Contact Us
16. Footer
17. Responsive

*/
@import url("https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,900|Ubuntu:400,400i,500,500i,700,700i&display=swap");


/* ------------------------------------------------------------------
    Template All Variables Defined - Value Change with Your's Need
 -------------------------------------------------------------------- */


.gradientHover, .theme-btn:hover, .domain-search-box .search-box-inner form button:hover {
  background: #f85a29; }

.section-padding {
  padding: 150px 0px; }
  @media (max-width: 1400px) {
    .section-padding {
      padding: 120px 0px; } }
  @media (max-width: 991px) {
    .section-padding {
      padding: 100px 0px; } }
  @media (max-width: 767px) {
    .section-padding {
      padding: 80px 0px; } }

.section-title {
  margin-bottom: 40px; }
  .section-title > span {
    height: 36px;
    width: 130px;
    margin: 0 auto;
    margin-bottom: 20px;
    display: inline-block;
    background-repeat: no-repeat;}
  .section-title h2 {
    margin-top: -10px;
    margin-bottom: 6px;
    text-transform: capitalize; }
    @media (max-width: 767px) {
      .section-title h2 {
        font-size: 30px;
        line-height: 42px; } }
    @media (max-width: 500px) {
      .section-title h2 {
        font-size: 28px;
        line-height: 40px; } }
  .section-title p span {
    color: #f93748; }
  @media (max-width: 500px) {
    .section-title p {
      font-size: 15px;
      line-height: 1.7; } }

/* --------------------------------------------
    Template Defualt Fonts & Fonts Styles
 ---------------------------------------------- */
body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  color: #4d4d4d;
  background: #fff; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Ubuntu", sans-serif;
  margin: 0px;
  font-weight: 700;
  color: #333; }

h1 {
  font-size: 48px;
  line-height: 1; }
  h1.fs-lg {
    font-size: 60px;
    line-height: 70px; }
    @media (max-width: 767px) {
      h1.fs-lg {
        font-size: 40px;
        line-height: 50px; } }
    @media (max-width: 480px) {
      h1.fs-lg {
        font-size: 32px;
        line-height: 44px; } }

h2 {
  font-size: 36px;
  line-height: 48px; }

h3 {
  font-size: 24px;
  line-height: 36px; }

h4 {
  font-size: 20px;
  line-height: 30px; }

h5 {
  font-size: 18px;
  line-height: 30px; }

h6 {
  font-size: 14px;
  line-height: 24px; }

a {
  text-decoration: none;
  font-weight: 400;
  outline: none !important;
  cursor: pointer;
  font-size: 16px;
  line-height: 30px; }

p {
  margin: 0px; }

.coverbg {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important; }



/* background */
.bg-cover {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: center; }

.bg-center {
  background-position: center; }

.bg-contain {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: relative; }

.bg-right {
  background-repeat: no-repeat;
  background-position: right; }

.bg-left {
  background-repeat: no-repeat;
  background-position: left; }

.bg-bottom {
  background-repeat: no-repeat;
  background-position: bottom; }

.bg-top {
  background-repeat: no-repeat;
  background-position: top; }

.bg-100 {
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%; }

.lr-9 {
  margin-left: -9px;
  margin-right: -9px; }
  .lr-9 [class*="col"] {
    padding-left: 9px;
    padding-right: 9px; }

.ofs {
  z-index: 0;
  overflow: unset;
  position: relative; }

section {
  z-index: 1;
  overflow: hidden;
  position: relative; }

.shape {
  position: absolute; }
  @media (max-width: 1191px) {
    .shape {
      opacity: 0;
      display: none; } }

.navbar-brand {
  padding: 0;
  margin-right: 0; }



.slick-dots {
  top: 10px;
  bottom: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 16px;
  list-style: none;
  position: relative;
  text-align: center; }
  @media (max-width: 599px) {
    .slick-dots {
      left: auto;
      right: 20px; } }
  .slick-dots li {

    margin: 0 5px;
    display: inline-block; }
    .slick-dots li button {
      content: "";
      width: 12px !important;
      height: 12px !important;
      line-height: 12px;
      position: relative;
      background: #ddefff !important;
      border-radius: 30px;
      -webkit-transition: .3s;
      -o-transition: .3s;
      transition: .3s; }
    .slick-dots li.slick-active button {
      width: 30px !important;
      margin-right: 10px;
      background-color: #f5faff; }
    .slick-dots li button::before {
      display: none; }

.domain-search-box .select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 0px;
  padding-right: 14px; }

.select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px; }

.select {
  color: #f93748;
  min-width: 100px;
  font-size: 16px;
  cursor: pointer;
  font-weight: 700;
  line-height: 70px;
  position: relative;
  float: left;
  z-index: 9999999; }
  @media (max-width: 380px) {
    .select {
      display: none;
    } 
    
}

.select .select-styled {
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  border-radius: 24px;
  height: 70px;
  line-height: 70px;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in; }

.select .select-styled:before, .select .select-styled:after {
  top: 0;
  right: 0;
  width: 48px;
  height: 100%;
  position: absolute; }

.select .select-styled:after {
  color: #f93748;
  content: "\f078";
  font-size: 14px;
  line-height: 70px;
  text-align: center;
  font-family: "Font Awesome 5 Pro";
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in; }

.select .select-styled:active, .select .select-styled.active {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select .select-styled:active:after, .select .select-styled.active:after {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg); }

.select .select-options {
  color: #FFF;
  right: 0;
  left: 0;
  margin: 0;
  top: 100%;
  padding: 0;
  z-index: 999999;
  display: none;
  list-style: none;
  overflow: hidden;
  position: absolute;
  background: #f93748;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }

.select .select-options.overflow-y {
  overflow: hidden;
  max-height: 235px;
  overflow-y: scroll; }

.select .select-options li {
  margin: 0;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  padding: 10px 10px;
  border-top: 1px solid #eee;
  -webkit-transition: all 0.15s ease-in;
  -moz-transition: all 0.15s ease-in;
  -ms-transition: all 0.15s ease-in;
  -o-transition: all 0.15s ease-in;
  transition: all 0.15s ease-in; }

.select .select-options li:first-child {
  border-top: none; }

.select .select-options li:before {
  content: none !important; }

.select .select-options li:hover {
  color: #FFF;
  background: #29156b; }

.select .select-options li[rel="hide"] {
  display: none; }

@-webkit-keyframes sticky {
  0% {
    opacity: 0;
    margin-top: -120px; }
  50% {
    opacity: 0;
    margin-top: -60px; }
  100% {
    opacity: 1;
    margin-top: 0; } }

@keyframes sticky {
  0% {
    opacity: 0;
    margin-top: -120px; }
  50% {
    opacity: 0;
    margin-top: -60px; }
  100% {
    opacity: 1;
    margin-top: 0; } }

@-webkit-keyframes reval {
  0% {
    opacity: 0;
    bottom: -20px; }
  50% {
    opacity: .5;
    bottom: -10px; }
  100% {
    opacity: 1;
    bottom: 0px; } }

@keyframes reval {
  0% {
    opacity: 0;
    bottom: -20px; }
  50% {
    opacity: .5;
    bottom: -10px; }
  100% {
    opacity: 1;
    bottom: 0px; } }

@-webkit-keyframes gbg {
  0% {
    opacity: .6;
    background-image: -moz-linear-gradient(0deg, #34b5bf 0%, #210c59 100%);
    background-image: -webkit-linear-gradient(0deg, #34b5bf 0%, #210c59 100%);
    background-image: linear-gradient(0deg, #34b5bf 0%, #210c59 100%); }
  20% {
    opacity: .7;
    background-image: -moz-linear-gradient(0deg, #34b5bf 0%, #210c59 100%);
    background-image: -webkit-linear-gradient(0deg, #34b5bf 0%, #210c59 100%);
    background-image: linear-gradient(0deg, #34b5bf 0%, #210c59 100%); }
  40% {
    opacity: .8;
    background-image: -moz-linear-gradient(0deg, #34b5bf 0%, #210c59 100%);
    background-image: -webkit-linear-gradient(0deg, #34b5bf 0%, #210c59 100%);
    background-image: linear-gradient(0deg, #34b5bf 0%, #210c59 100%); }
  60% {
    opacity: .9;
    background-image: -moz-linear-gradient(0deg, #34b5bf 0%, #210c59 100%);
    background-image: -webkit-linear-gradient(0deg, #34b5bf 0%, #210c59 100%);
    background-image: linear-gradient(0deg, #34b5bf 0%, #210c59 100%); }
  80% {
    opacity: 1;
    background-image: -moz-linear-gradient(0deg, #34b5bf 0%, #210c59 100%);
    background-image: -webkit-linear-gradient(0deg, #34b5bf 0%, #210c59 100%);
    background-image: linear-gradient(0deg, #34b5bf 0%, #210c59 100%); }
  100% {
    opacity: 1;
    background-image: -moz-linear-gradient(0deg, #34b5bf 0%, #210c59 100%);
    background-image: -webkit-linear-gradient(0deg, #34b5bf 0%, #210c59 100%);
    background-image: linear-gradient(0deg, #34b5bf 0%, #210c59 100%); } }

@keyframes gbg {
  0% {
    opacity: .6;
    background-image: -moz-linear-gradient(0deg, #34b5bf 0%, #210c59 100%);
    background-image: -webkit-linear-gradient(0deg, #34b5bf 0%, #210c59 100%);
    background-image: linear-gradient(0deg, #34b5bf 0%, #210c59 100%); }
  20% {
    opacity: .7;
    background-image: -moz-linear-gradient(0deg, #34b5bf 0%, #210c59 100%);
    background-image: -webkit-linear-gradient(0deg, #34b5bf 0%, #210c59 100%);
    background-image: linear-gradient(0deg, #34b5bf 0%, #210c59 100%); }
  40% {
    opacity: .8;
    background-image: -moz-linear-gradient(0deg, #34b5bf 0%, #210c59 100%);
    background-image: -webkit-linear-gradient(0deg, #34b5bf 0%, #210c59 100%);
    background-image: linear-gradient(0deg, #34b5bf 0%, #210c59 100%); }
  60% {
    opacity: .9;
    background-image: -moz-linear-gradient(0deg, #34b5bf 0%, #210c59 100%);
    background-image: -webkit-linear-gradient(0deg, #34b5bf 0%, #210c59 100%);
    background-image: linear-gradient(0deg, #34b5bf 0%, #210c59 100%); }
  80% {
    opacity: 1;
    background-image: -moz-linear-gradient(0deg, #34b5bf 0%, #210c59 100%);
    background-image: -webkit-linear-gradient(0deg, #34b5bf 0%, #210c59 100%);
    background-image: linear-gradient(0deg, #34b5bf 0%, #210c59 100%); }
  100% {
    opacity: 1;
    background-image: -moz-linear-gradient(0deg, #34b5bf 0%, #210c59 100%);
    background-image: -webkit-linear-gradient(0deg, #34b5bf 0%, #210c59 100%);
    background-image: linear-gradient(0deg, #34b5bf 0%, #210c59 100%); } }

@-webkit-keyframes playVideo {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(150, 29, 134, 0.3);
    box-shadow: 0 0 0 0 rgba(150, 29, 134, 0.3); }
  40% {
    -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0); }
  80% {
    -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0); } }

@keyframes playVideo {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(150, 29, 134, 0.3);
    box-shadow: 0 0 0 0 rgba(150, 29, 134, 0.3); }
  40% {
    -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0); }
  80% {
    -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0); } }

@-webkit-keyframes fadewave {
  0% {
    -webkit-box-shadow: 0 0 0 0 #961d86;
    box-shadow: 0 0 0 0 #961d86; }
  50% {
    -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0); } }

@keyframes fadewave {
  0% {
    -webkit-box-shadow: 0 0 0 0 #961d86;
    box-shadow: 0 0 0 0 #961d86; }
  50% {
    -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0); } }

@-webkit-keyframes fadewave2 {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 131, 104, 0.8);
    box-shadow: 0 0 0 0 rgba(0, 131, 104, 0.8); }
  50% {
    -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0); } }

@keyframes fadewave2 {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 131, 104, 0.8);
    box-shadow: 0 0 0 0 rgba(0, 131, 104, 0.8); }
  50% {
    -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0); } }

@-webkit-keyframes dimond {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes dimond {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes bounce {
  0%, 100% {
    -webkit-transform: scale(0); }
  50% {
    -webkit-transform: scale(1); } }

@keyframes bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0); }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1); } }

@-webkit-keyframes movebounce {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); } }

@keyframes movebounce {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); } }

@-webkit-keyframes bounceUp {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px); }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); } }

@keyframes bounceUp {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); }
  50% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px); }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); } }

@-webkit-keyframes moveleftbounce {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px); }
  50% {
    -webkit-transform: translateX(40px);
    transform: translateX(40px); }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px); } }

@keyframes moveleftbounce {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px); }
  50% {
    -webkit-transform: translateX(40px);
    transform: translateX(40px); }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px); } }

@-webkit-keyframes moverightbounce {
  0% {
    margin-left: 0px; }
  50% {
    margin-left: 30px; }
  100% {
    margin-left: 0px; } }

@keyframes moverightbounce {
  0% {
    margin-left: 0px; }
  50% {
    margin-left: 30px; }
  100% {
    margin-left: 0px; } }

.rotateme {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 20s;
  animation-duration: 20s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear; }

@keyframes guraguri {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes guraguri {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg); } }

@-webkit-keyframes rotate3d {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg); }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg); } }

@keyframes rotate3d {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg); }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg); } }

@keyframes move_wave {
  0% {
    -webkit-transform: translateX(0) translateZ(0) scaleY(1);
    transform: translateX(0) translateZ(0) scaleY(1); }
  50% {
    -webkit-transform: translateX(-25%) translateZ(0) scaleY(0.55);
    transform: translateX(-25%) translateZ(0) scaleY(0.55); }
  100% {
    -webkit-transform: translateX(-50%) translateZ(0) scaleY(1);
    transform: translateX(-50%) translateZ(0) scaleY(1); } }

@-webkit-keyframes move_wave {
  0% {
    -webkit-transform: translateX(0) translateZ(0) scaleY(1);
    transform: translateX(0) translateZ(0) scaleY(1); }
  50% {
    -webkit-transform: translateX(-25%) translateZ(0) scaleY(0.55);
    transform: translateX(-25%) translateZ(0) scaleY(0.55); }
  100% {
    -webkit-transform: translateX(-50%) translateZ(0) scaleY(1);
    transform: translateX(-50%) translateZ(0) scaleY(1); } }

/* ----------------------------------
  All Aniamtion Styles
 ------------------------------------ */
.theme-btn {
  color: #ffffff !important;
  padding: 15px 30px;
  border-radius: 35px;
  line-height: 1; 
  background: #fb0053;
}
  .theme-btn:hover {
    color: #FFF; }
  .theme-btn i, .theme-btn img {
    margin-left: 5px;
    margin-top: -2px; }

.pre-loader {
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  overflow: hidden;
  text-align: center;
  z-index: 999999999999; }
  .pre-loader .loader {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 80px;
    height: auto; }
  .pre-loader .spinner {
    text-align: center; }
    .pre-loader .spinner > div {
      width: 18px;
      height: 18px;
      background-color: #FFF;
      border-radius: 100%;
      display: inline-block;
      -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both; }
    .pre-loader .spinner .bounce1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s; }
    .pre-loader .spinner .bounce2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s; }

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1); } }

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }


/* ----------------------------------
	Service Section Styles
 ------------------------------------ */
.services-wrap.service-three {
  background-image: -moz-linear-gradient(130deg, #3a2975 0%, #135885 100%);
  background-image: -webkit-linear-gradient(130deg, #3a2975 0%, #135885 100%);
  background-image: linear-gradient(130deg, #3a2975 0%, #135885 100%); }

.single-service-box {
  margin-top: 40px;
  min-width: 200px;
  text-align: center;
  padding: 20px 10px;
  border-radius: 10px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(20, 27, 201, 0.17);
  box-shadow: 0px 0px 15px 0px rgba(20, 27, 201, 0.17);
  background-color: white; }
  @media (max-width: 767px) {
    .single-service-box {
      padding: 35px 15px 30px; } }
  .single-service-box:hover {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px); }

  .single-service-box .service-icon {
    margin-bottom: 21px; 
    .icon-img {
      max-height: 70px; 
    }
  }
  .single-service-box h4 {
    color: #31389e;
    text-transform: capitalize; }
  .single-service-box p {
    font-size: 15px;
    color: #101341;
    margin-top: 14px;
    line-height: 26px; }

.service-box-2 {
  margin-top: 75px;
  padding: 0px 60px;
  padding-bottom: 30px;
  min-height: 198px;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-box-shadow: 0px 0px 25px 0px rgba(20, 27, 201, 0.17);
  box-shadow: 0px 0px 25px 0px rgba(20, 27, 201, 0.17); }
  @media (max-width: 1199px) {
    .service-box-2 {
      padding: 0px 30px;
      padding-bottom: 30px; } }
  @media (max-width: 767px) {
    .service-box-2 {
      padding: 0px 40px; } }
  .service-box-2.servicev3 {
    background-color: #1f2856; }
    .service-box-2.servicev3 h4, .service-box-2.servicev3 p {
      color: #FFF; }
  .service-box-2:hover {
    background-image: -moz-linear-gradient(-45deg, #fb0054 0%, #f55b2a 100%);
    background-image: -webkit-linear-gradient(-45deg, #fb0054 0%, #f55b2a 100%);
    background-image: linear-gradient(-45deg, #fb0054 0%, #f55b2a 100%);
    -webkit-box-shadow: 0px 0px 25px 0px rgba(20, 27, 201, 0.05);
    box-shadow: 0px 0px 25px 0px rgba(20, 27, 201, 0.05);
    cursor: pointer; }
    .service-box-2:hover h4, .service-box-2:hover p {
      color: #FFF; }
  .service-box-2 .service-icon {
    margin-bottom: 0px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }
  .service-box-2 h4 {
    font-size: 20px;
    line-height: 1;
    font-weight: 500;
    color: #3c44a9;
    margin-top: -10px; }
  .service-box-2 p {
    color: #61648e;
    line-height: 24px;
    margin-top: 20px; }

.service-two .row .col-12:nth-child(1) .service-box-2:hover {
  background-image: -moz-linear-gradient(-45deg, #34b5bf 0%, #210c59 100%);
  background-image: -webkit-linear-gradient(-45deg, #34b5bf 0%, #210c59 100%);
  background-image: linear-gradient(-45deg, #34b5bf 0%, #210c59 100%); }

.service-two .row .col-12:nth-child(3) .service-box-2:hover {
  background-image: -moz-linear-gradient(-45deg, #3615e7 0%, #44a2f6 100%);
  background-image: -webkit-linear-gradient(-45deg, #3615e7 0%, #44a2f6 100%);
  background-image: linear-gradient(-45deg, #3615e7 0%, #44a2f6 100%); }

.service-two .row .col-12:nth-child(4) .service-box-2:hover {
  background-image: -moz-linear-gradient(-45deg, #fc6a0e 0%, #fdb642 100%);
  background-image: -webkit-linear-gradient(-45deg, #fc6a0e 0%, #fdb642 100%);
  background-image: linear-gradient(-45deg, #fc6a0e 0%, #fdb642 100%); }

.service-two .row .col-12:nth-child(5) .service-box-2:hover {
  background-image: -webkit-linear-gradient(-45deg, #8d40fb 0%, #5a57fb 100%);
  background-image: linear-gradient(-45deg, #8d40fb 0%, #5a57fb 100%); }

.service-two .row .col-12:nth-child(6) .service-box-2:hover {
  background-image: -moz-linear-gradient(-45deg, #27b88d 0%, #22dd73 100%);
  background-image: -webkit-linear-gradient(-45deg, #27b88d 0%, #22dd73 100%);
  background-image: linear-gradient(-45deg, #27b88d 0%, #22dd73 100%); }

@media (max-width: 1191px) {
  .price-wrap .row.mt-40 {
    margin-top: 0px; } }


//New designs for children single-price-table
.price-wrap .tab-content .col-12 .single-price-table .package-features-price {
  background: #2aabf5;
  background: -moz-linear-gradient(120deg, #2aabf5 0%, #012ef8 100%);
  background: -webkit-linear-gradient(120deg, #2aabf5 0%, #012ef8 100%);
  background: -webkit-linear-gradient(330deg, #2aabf5 0%, #012ef8 100%);
  background: -o-linear-gradient(330deg, #2aabf5 0%, #012ef8 100%);
  background: linear-gradient(120deg, #2aabf5 0%, #012ef8 100%); }

.price-wrap .tab-content .col-12 .single-price-table.active, .price-wrap .tab-content .col-12 .single-price-table:hover {
  background: #2aabf5;
  background: -moz-linear-gradient(120deg, #2aabf5 0%, #012ef8 100%);
  background: -webkit-linear-gradient(120deg, #2aabf5 0%, #012ef8 100%);
  background: -webkit-linear-gradient(330deg, #2aabf5 0%, #012ef8 100%);
  background: -o-linear-gradient(330deg, #2aabf5 0%, #012ef8 100%);
  background: linear-gradient(120deg, #2aabf5 0%, #012ef8 100%); }
  .price-wrap .tab-content .col-12 .single-price-table.active .package-btn a, .price-wrap .tab-content .col-12 .single-price-table:hover .package-btn a {
    background: #2aabf5;
  background: -moz-linear-gradient(120deg, #2aabf5 0%, #012ef8 100%);
  background: -webkit-linear-gradient(120deg, #2aabf5 0%, #012ef8 100%);
  background: -webkit-linear-gradient(330deg, #2aabf5 0%, #012ef8 100%);
  background: -o-linear-gradient(330deg, #2aabf5 0%, #012ef8 100%);
  background: linear-gradient(120deg, #2aabf5 0%, #012ef8 100%); }
  .price-wrap .tab-content .col-12 .single-price-table.active li i, .price-wrap .tab-content .col-12 .single-price-table:hover li i {
    color: #2a60f5; }

.price-wrap .tab-content .col-12 .package-btn a {
  background: #2aabf5;
  background: -moz-linear-gradient(120deg, #2aabf5 0%, #012ef8 100%);
  background: -webkit-linear-gradient(120deg, #2aabf5 0%, #012ef8 100%);
  background: -webkit-linear-gradient(330deg, #2aabf5 0%, #012ef8 100%);
  background: -o-linear-gradient(330deg, #2aabf5 0%, #012ef8 100%);
  background: linear-gradient(120deg, #2aabf5 0%, #012ef8 100%); }


.package-control ul {
  border-width: 1px;
  border-color: white;
  border-style: solid;
  background-color: #efeff3;
  width: 250px;
  height: 48px;
  border-radius: 30px;
  line-height: 1;
  overflow: hidden;
  text-align: center;
  margin: 0 auto;
  position: relative;
  z-index: 1; }
  .package-control ul li {
    position: relative; }
    .package-control ul li.active a {
      color: #FFF; }
    .package-control ul li.active::before {
      -webkit-transition: .3s;
      -o-transition: .3s;
      transition: .3s;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      border-radius: 30px;
      -webkit-box-shadow: 0px 0px 25px 0px rgba(45, 21, 183, 0.1);
      box-shadow: 0px 0px 25px 0px rgba(45, 21, 183, 0.1);
      background-image: -moz-linear-gradient(-45deg, #fb0053 0%, #f85a29 100%);
      background-image: -webkit-linear-gradient(-45deg, #fb0053 0%, #f85a29 100%);
      background-image: linear-gradient(-45deg, #fb0053 0%, #f85a29 100%); }
    .package-control ul li a {
      height: 48px;
      line-height: 48px;
      border-radius: 30px;
      font-size: 14px;
      font-weight: 500;
      position: relative;
      display: inline-block;
      padding: 0px 39.5px;
      color: #000; }

.single-price-table {
  transition: .3s linear;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  margin-top: 40px;
  border-radius: 10px;
  background-color: #FFF;
  color: #FFF;
  -webkit-box-shadow: 0px 0px 25px 0px rgba(35, 41, 179, 0.14);
  box-shadow: 0px 0px 25px 0px rgba(35, 41, 179, 0.14);
}
  .single-price-table .package-name {
    padding: 28px 0px 20px; }
    .single-price-table .package-name h3 {
      font-size: 30px;
      line-height: 1; }
    .single-price-table .package-name p {
      font-size: 14px;
      line-height: 1;
      margin-top: 5px; }
  .single-price-table .package-features-price {
    -webkit-transition: .3s linear;
    -o-transition: .3s linear;
    transition: .3s linear;
    padding: 40px 45px 50px;
    background-image: -moz-linear-gradient(140deg, #27b88d 0%, #22dd73 100%);
    background-image: -webkit-linear-gradient(140deg, #27b88d 0%, #22dd73 100%);
    background-image: linear-gradient(140deg, #27b88d 0%, #22dd73 100%); }
    .single-price-table .package-features-price .price span {
      line-height: 1;
      font-size: 30px;
      font-weight: 700;
      position: relative;
      margin-bottom: 20px;
      display: inline-block; }
      .single-price-table .package-features-price .price span::before {
        position: absolute;
        left: -20px;
        content: "$"; }
    .single-price-table .package-features-price li {
      margin-top: 5px; }
      .single-price-table .package-features-price li i {
        margin-right: 10px; }
      .single-price-table .package-features-price li.del {
        opacity: .5; }
  .single-price-table .package-btn {
    text-align: center;
    padding-bottom: 3px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }
    .single-price-table .package-btn a {
      color: #FFF;
      line-height: 1;
      font-weight: 700;
      padding: 18px 42px;
      border-radius: 23px;
      display: inline-block;
      text-transform: uppercase;
      -webkit-box-shadow: 0px 0px 25px 0px rgba(20, 27, 201, 0.05);
      box-shadow: 0px 0px 25px 0px rgba(20, 27, 201, 0.05);
      -webkit-transition: .3s;
      -o-transition: .3s;
      transition: .3s;
      background-image: -moz-linear-gradient(90deg, #27b88d 0%, #22dd73 100%);
      background-image: -webkit-linear-gradient(90deg, #27b88d 0%, #22dd73 100%);
      background-image: linear-gradient(90deg, #27b88d 0%, #22dd73 100%); }
      .single-price-table .package-btn a:hover {
        background-image: -moz-linear-gradient(-190deg, #27b88d 0%, #22dd73 100%);
        background-image: -webkit-linear-gradient(-190deg, #27b88d 0%, #22dd73 100%);
        background-image: linear-gradient(-190deg, #27b88d 0%, #22dd73 100%); }
  .single-price-table.active, .single-price-table:hover {
    background-image: -moz-linear-gradient(140deg, #27b88d 0%, #22dd73 100%);
    background-image: -webkit-linear-gradient(140deg, #27b88d 0%, #22dd73 100%);
    background-image: linear-gradient(140deg, #27b88d 0%, #22dd73 100%); }
    .single-price-table.active .package-name h3, .single-price-table.active .package-name span, .single-price-table:hover .package-name h3, .single-price-table:hover .package-name span {
      color: #FFF; }
    .single-price-table.active .package-features-price, .single-price-table:hover .package-features-price {
      -webkit-transition: .3s linear;
      -o-transition: .3s linear;
      transition: .3s linear;
      background: #fff !important;
      color: #29156b; }
    .single-price-table.active li i, .single-price-table:hover li i {
      -webkit-transition: .3s linear;
      -o-transition: .3s linear;
      transition: .3s linear;
      color: #27b88d; }

.single-price-box {
  margin-top: 30px;
  border-radius: 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-image: -moz-linear-gradient(180deg, #c064de 0%, #5c34ff 100%);
  background-image: -webkit-linear-gradient(180deg, #c064de 0%, #5c34ff 100%);
  background-image: linear-gradient(180deg, #c064de 0%, #5c34ff 100%);
  -webkit-box-shadow: 0px 0px 25px 0px rgba(20, 27, 201, 0.05);
  box-shadow: 0px 0px 25px 0px rgba(20, 27, 201, 0.05); }
  .single-price-box.active a, .single-price-box:hover a {
    background-image: -moz-linear-gradient(0deg, #fb0054 0%, #f55b2a 100%);
    background-image: -webkit-linear-gradient(0deg, #fb0054 0%, #f55b2a 100%);
    background-image: linear-gradient(0deg, #fb0054 0%, #f55b2a 100%);
    color: #FFF !important; }
  .single-price-box.active {
    background: #FFF;
    position: relative;
    color: #FFF;
    margin-top: -5px;
    -webkit-box-shadow: 0px 10px 25px 0px rgba(123, 147, 171, 0.15);
    box-shadow: 0px 10px 25px 0px rgba(123, 147, 171, 0.15); }
    @media (max-width: 1191px) {
      .single-price-box.active {
        margin-top: 30px; } }
    .single-price-box.active .price span {
      color: #FFF; }
    .single-price-box.active .package-name h3, .single-price-box.active .package-name span {
      color: #29156b; }
    .single-price-box.active .package-features-price {
      background-image: -moz-linear-gradient(0deg, #fb0054 0%, #f55b2a 100%);
      background-image: -webkit-linear-gradient(0deg, #fb0054 0%, #f55b2a 100%);
      background-image: linear-gradient(0deg, #fb0054 0%, #f55b2a 100%);
      -webkit-box-shadow: 0px 0px 25px 0px rgba(20, 27, 201, 0.05);
      box-shadow: 0px 0px 25px 0px rgba(20, 27, 201, 0.05);
      padding: 100px 80px; }
      @media (max-width: 1191px) {
        .single-price-box.active .package-features-price {
          padding: 60px 40px; } }
      .single-price-box.active .package-features-price::before {
        background-image: -moz-linear-gradient(180deg, #fb0054 0%, #f55b2a 100%);
        background-image: -webkit-linear-gradient(180deg, #fb0054 0%, #f55b2a 100%);
        background-image: linear-gradient(180deg, #fb0054 0%, #f55b2a 100%); }
      .single-price-box.active .package-features-price .package-name h3 {
        color: #29156b; }
      .single-price-box.active .package-features-price .package-name span {
        color: #444; }
  .single-price-box .package-name {
    padding: 20px 0px; }
    .single-price-box .package-name h3 {
      color: #FFF; }
    .single-price-box .package-name span {
      color: #FFF; }
  .single-price-box .package-features-price {
    background: #fff;
    overflow: hidden;
    position: relative;
    padding: 80px 80px 60px; }
    @media (max-width: 1191px) {
      .single-price-box .package-features-price {
        padding: 60px 40px; } }
        .price-two .section-title h2, .price-two .section-title p {
          color: #fff;
      }
    .single-price-box .package-features-price::before {
      left: -1px;
      top: -20px;
      content: "";
      width: 100%;
      height: 30px;
      position: absolute;
      -webkit-transform: rotate(-3deg);
      -ms-transform: rotate(-3deg);
      transform: rotate(-3deg);
      background-image: -moz-linear-gradient(0deg, #c064de 0%, #5c34ff 100%);
      background-image: -webkit-linear-gradient(0deg, #c064de 0%, #5c34ff 100%);
      background-image: linear-gradient(0deg, #c064de 0%, #5c34ff 100%); }
    .single-price-box .package-features-price li {
      margin-top: 5px; }
      .single-price-box .package-features-price li i {
        margin-right: 10px; }
      .single-price-box .package-features-price li.del {
        opacity: .5; }
  .single-price-box .price {
    margin-bottom: 35px; }
    .single-price-box .price span {
      color: #29156b;
      line-height: 1;
      font-size: 30px;
      font-weight: 700;
      position: relative;
      display: inline-block; }
      .single-price-box .price span::before {
        position: absolute;
        left: -18px;
        content: "$"; }
  .single-price-box .package-btn {
    text-align: center;
    padding: 30px 0px; }
    .single-price-box .package-btn a {
      border-radius: 23px;
      background-color: #FFF;
      -webkit-box-shadow: 0px 0px 20px 0px rgba(20, 27, 201, 0.05);
      box-shadow: 0px 0px 20px 0px rgba(20, 27, 201, 0.05);
      width: 148px;
      height: 46px;
      color: #606391;
      line-height: 46px;
      display: inline-block;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 700;
      -webkit-transition: .3s;
      -o-transition: .3s;
      transition: .3s; }

.price-two {
  position: relative;
  z-index: 1; }
  .price-two::before {
    background-image: -moz-linear-gradient(0deg, #25286e 0%, #5e218f 100%);
    background-image: -webkit-linear-gradient(0deg, #25286e 0%, #5e218f 100%);
    background-image: linear-gradient(0deg, #25286e 0%, #5e218f 100%);
    width: 100%;
    height: 70%;
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0; }
    @media (max-width: 991px) {
      .price-two::before {
        height: 90%; } }

.single-package {
  z-index: 1;
  margin-top: 70px;
  padding: 40px 0px;
  position: relative;
  border-radius: 10px;
  background-color: #FFF;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(20, 27, 201, 0.05);
  box-shadow: 0px 0px 20px 0px rgba(20, 27, 201, 0.05); }
  .single-package.active {
    padding: 60px 0px;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px); }
    @media (max-width: 1199px) {
      .single-package.active {
        -webkit-transform: translateY(0px);
        -ms-transform: translateY(0px);
        transform: translateY(0px);
        padding: 40px 0px; } }
    .single-package.active .package-btn a {
      background-image: -moz-linear-gradient(0deg, #fb0054 0%, #f55b2a 100%);
      background-image: -webkit-linear-gradient(0deg, #fb0054 0%, #f55b2a 100%);
      background-image: linear-gradient(0deg, #fb0054 0%, #f55b2a 100%);
      -webkit-box-shadow: 0px 0px 25px 0px rgba(20, 27, 201, 0.05);
      box-shadow: 0px 0px 25px 0px rgba(20, 27, 201, 0.05); }
      .single-package.active .package-btn a:hover {
        background-image: -moz-linear-gradient(-100deg, #fb0054 0%, #f55b2a 100%);
        background-image: -webkit-linear-gradient(-100deg, #fb0054 0%, #f55b2a 100%);
        background-image: linear-gradient(-100deg, #fb0054 0%, #f55b2a 100%); }
  .single-package .package-shape {
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    z-index: -1;
    min-height: 100%;
    position: absolute;
    background-repeat: no-repeat; }
    @media (max-width: 1200px) {
      .single-package .package-shape {
        background-size: contain; } }
  .single-package .package-name {
    text-align: center; }
    .single-package .package-name h3 {
      color: #FFF;
      line-height: 1;
      font-size: 34px;
      font-weight: 700;
      margin-bottom: 34px; }
  .single-package .package-features-price {
    padding: 0px 95px; }
    @media (max-width: 1200px) {
      .single-package .package-features-price {
        text-align: center; } }
    @media (max-width: 991px) {
      .single-package .package-features-price {
        text-align: center;
        padding: 0px 30px; } }
    .single-package .package-features-price .price {
      width: 168px;
      height: 168px;
      margin: 0px auto;
      line-height: 168px;
      border-radius: 50%;
      text-align: center;
      margin-bottom: 40px;
      background-color: white;
      -webkit-box-shadow: 0px 0px 25px 0px rgba(20, 27, 201, 0.05);
      box-shadow: 0px 0px 25px 0px rgba(20, 27, 201, 0.05); }
      .single-package .package-features-price .price span {
        font-size: 30px;
        margin-left: 9px;
        font-weight: 700;
        position: relative;
        color: #29156b;
        display: inline-block; }
        .single-package .package-features-price .price span::before {
          left: -18px;
          content: "$";
          position: absolute; }
    .single-package .package-features-price li {
      color: #61648e;
      margin-top: 5px; }
      .single-package .package-features-price li i {
        margin-right: 8px; }
      .single-package .package-features-price li.del {
        opacity: .5; }
  .single-package .package-btn {
    margin-top: 30px;
    text-align: center; }
    .single-package .package-btn a {
      color: #FFF;
      line-height: 1px;
      font-size: 15px;
      font-weight: 600;
      padding: 25px 43px;
      border-radius: 25px;
      display: inline-block;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      text-transform: uppercase;
      -webkit-box-shadow: 0px 0px 25px 0px rgba(20, 27, 201, 0.05);
      box-shadow: 0px 0px 25px 0px rgba(20, 27, 201, 0.05);
      background-image: -moz-linear-gradient(0deg, #069ebf 0%, #04d8d6 100%);
      background-image: -webkit-linear-gradient(0deg, #069ebf 0%, #04d8d6 100%);
      background-image: linear-gradient(0deg, #069ebf 0%, #04d8d6 100%); }
      .single-package .package-btn a:hover {
        background-image: -moz-linear-gradient(180deg, #069ebf 0%, #04d8d6 100%);
        background-image: -webkit-linear-gradient(180deg, #069ebf 0%, #04d8d6 100%);
        background-image: linear-gradient(180deg, #069ebf 0%, #04d8d6 100%); }
    .single-package .package-btn.price-btn3 a {
      background-image: -moz-linear-gradient(-45deg, #302be8 0%, #418df5 100%);
      background-image: -webkit-linear-gradient(-45deg, #302be8 0%, #418df5 100%);
      background-image: linear-gradient(-45deg, #302be8 0%, #418df5 100%); }
      .single-package .package-btn.price-btn3 a:hover {
        background-image: -moz-linear-gradient(-145deg, #302be8 0%, #418df5 100%);
        background-image: -webkit-linear-gradient(-145deg, #302be8 0%, #418df5 100%);
        background-image: linear-gradient(-145deg, #302be8 0%, #418df5 100%); }

.map-wrap.map-2 {
  background-color: #fff; }
  .map-wrap.map-2 .single-country p {
    color: #29156b !important;
    font-weight: 600; }

.map-wrap .map-bg svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.map-wrap .server-country-list div {
  position: absolute;
  top: 0; }

.map-wrap .server-country-list .single-country p {
  line-height: 1;
  position: relative;
  padding-left: 25px; }
  .map-wrap .server-country-list .single-country p::before {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    content: "";
    background: #fff;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-animation: fadewave 2.4s infinite;
    animation: fadewave 2.4s infinite;
    -webkit-animation-delay: .1s !important;
    animation-delay: .1s !important; }
  .map-wrap .server-country-list .single-country p.right {
    padding-left: 0;
    padding-right: 25px; }
    .map-wrap .server-country-list .single-country p.right::before {
      left: auto;
      right: 0;
      -webkit-animation: fadewave2 2.5s infinite;
      animation: fadewave2 2.5s infinite;
      -webkit-animation-delay: .4s !important;
      animation-delay: .4s !important; }

.map-wrap .server-country-list .single-country:nth-child(1) {
  right: 214px;
  top: 80px; }

.map-wrap .server-country-list .single-country:nth-child(2) {
  right: 415px;
  top: 100px; }
  .map-wrap .server-country-list .single-country:nth-child(2) p::before {
    -webkit-animation: fadewave2 2.5s infinite;
    animation: fadewave2 2.5s infinite;
    -webkit-animation-delay: .3s !important;
    animation-delay: .3s !important; }

.map-wrap .server-country-list .single-country:nth-child(3) {
  left: 10px;
  top: 180px; }

.map-wrap .server-country-list .single-country:nth-child(4) {
  left: 200px;
  top: 160px; }

.map-wrap .server-country-list .single-country:nth-child(5) {
  left: 55%;
  top: 270px; }
  @media (max-width: 991px) {
    .map-wrap .server-country-list .single-country:nth-child(5) {
      left: 50%;
      top: 140px; } }

.map-wrap .server-country-list .single-country:nth-child(8) {
  left: 55%;
  top: auto;
  bottom: 100px; }

.map-wrap .server-country-list .single-country:nth-child(7) {
  left: 15%;
  top: auto;
  bottom: 200px; }
  @media (max-width: 991px) {
    .map-wrap .server-country-list .single-country:nth-child(7) {
      bottom: 100px; } }

.map-wrap .server-country-list .single-country:nth-child(6) {
  right: 3%;
  bottom: 220px;
  top: auto; }
  .map-wrap .server-country-list .single-country:nth-child(6) p::before {
    -webkit-animation: fadewave2 2.5s infinite;
    animation: fadewave2 2.5s infinite;
    -webkit-animation-delay: .3s !important;
    animation-delay: .3s !important; }

.map-wrap .server-country-list .single-country:nth-child(9) {
  right: 6%;
  bottom: 90px;
  top: auto; }


  /* ----------------------------------
	Content Block Section Styles
 ------------------------------------ */
.position, .content-block-wrap .content-block span::before, .content-block-wrap .content-block ul li::before {
  left: 0;
  top: 50%;
  position: absolute;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }

@media (max-width: 991px) {
  .content-block-wrap.pb-130 {
    padding-bottom: 100px; } }

@media (max-width: 767px) {
  .content-block-wrap.pb-130 {
    padding-bottom: 80px; } }

.content-block-wrap .content-block {
  margin-top: 40px; }
  @media (max-width: 991px) {
    .content-block-wrap .content-block.pt-60 {
      padding-top: 0; } }
  .content-block-wrap .content-block span {
    color: #3131e9;
    font-size: 18px;
    line-height: 1;
    position: relative;
    text-transform: uppercase;
    padding-left: 70px;
    font-weight: 500;
    margin-bottom: 40px;
    display: inline-block; }
    .content-block-wrap .content-block span::before {
      width: 60px;
      height: 4px;
      content: "";
      background: #000;
      background-image: -moz-linear-gradient(0deg, #3615e7 0%, #74bdff 100%);
      background-image: -webkit-linear-gradient(0deg, #3615e7 0%, #74bdff 100%);
      background-image: linear-gradient(0deg, #3615e7 0%, #74bdff 100%); }
  .content-block-wrap .content-block h2 {
    color: #29156b;
    font-size: 40px;
    line-height: 55px;
    text-transform: capitalize;
    margin-bottom: 20px;
    font-weight: 500;
    margin-top: -12px; }
    @media (max-width: 500px) {
      .content-block-wrap .content-block h2 {
        font-size: 28px;
        line-height: 38px; } }
  .content-block-wrap .content-block p {
    margin-bottom: 20px; }
  .content-block-wrap .content-block ul li {
    position: relative;
    padding-left: 30px;
    font-weight: 500;
    color: #6156ae;
    margin-top: 8px;
    display: block;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s; }
    .content-block-wrap .content-block ul li::before {
      content: "\f00c";
      font-family: "Font Awesome 5 Pro";
      font-weight: 700;
      color: #3131e9; }
    .content-block-wrap .content-block ul li:hover {
      -webkit-transform: translateX(5px);
      -ms-transform: translateX(5px);
      transform: translateX(5px);
      cursor: pointer; }

.content-block-wrap .content-featured-img {
  margin-top: 40px; }
  @media (max-width: 991px) {
    .content-block-wrap .content-featured-img {
      text-align: center; } }

.content-block-wrap .features-promo .single-fp {
  margin-top: 40px; }
  .content-block-wrap .features-promo .single-fp .fp-icon {
    float: left;
    overflow: hidden;
    margin-right: 27px; }
  .content-block-wrap .features-promo .single-fp .fp-text {
    overflow: auto; }
    .content-block-wrap .features-promo .single-fp .fp-text h4 {
      margin-top: -5px; }
      @media (max-width: 500px) {
        .content-block-wrap .features-promo .single-fp .fp-text h4 {
          font-size: 18px; } }
    .content-block-wrap .features-promo .single-fp .fp-text p {
      color: #555;
      margin-bottom: 0; }
      @media (max-width: 500px) {
        .content-block-wrap .features-promo .single-fp .fp-text p {
          font-size: 15px;
          line-height: 1.8; } }