.contact {
  .contact-box {
    background-image: linear-gradient(250deg, #4e95ed 0%, $secondaryColor 100%);
    padding: 80px 30px 80px 30px;
    border-radius: 5px 5px 5px 5px;
    .address-box {
      display: flex;
      align-items: center;
      .address-icon {
        background-image: linear-gradient(275deg, $whiteColor 0%, #fcfcff 100%);
        min-width: 48px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        width: 40px;
        border-radius: 50%;
        margin-right: 23px;
        i {
          font-size: 20px;
          line-height: 20px;
          color: #03228f;
        }
      }
      .address-text {
        .label {
          font-size: 16px;
          font-weight: 600;
          color: $whiteColor;
          display: block;
          margin-bottom: 5px;
        }
        a {
          color: $whiteColor;
          &:hover {
            color: #ccc;
          }
        }
        .desc {
          color: $whiteColor;
        }
      }
      &.onepage-box {
        background-image: linear-gradient(220deg, #f27c1e 0%, #dd4c23 79%);
        .address-box {
          .address-icon {
            background: $whiteColor;
            i {
              color: $orangeColor;
            }
          }
        }
        &.modify1 {
          background-image: linear-gradient(250deg, #9c9ff9 0%, #6e71db 100%);
          .address-box {
            .address-icon {
              background: $whiteColor;
              i {
                color: #787cf2;
              }
            }
          }
        }
      }
    }
    &.orange-box {
      background-image: linear-gradient(220deg, #f27c1e 0%, #dd4c23 79%);
      .address-box .address-icon i {
        color: #f2541b;
      }
    }
    &.purple-box {
      background-image: linear-gradient(250deg, #9c9ff9 0%, #6e71db 100%);
      .address-box .address-icon i {
        color: #787cf2;
      }
    }
  }
  // &.style2 {
  //   .contact-box-wrap {
  //     background-image: linear-gradient(
  //       270deg,
  //       #4e95ed 0%,
  //       $secondaryColor 100%
  //     );
  //     border-radius: 5px 5px 5px 5px;
  //     padding: 90px 60px 60px 60px;
  //     .btn-part {
  //       text-align: center;
  //       button {
  //         background-image: linear-gradient(250deg, #4e95ed 0%, #03228f 100%);
  //         padding: 18px 36px 16px 36px;
  //         font-size: 14px;
  //         font-weight: 500;
  //       }
  //     }
  //     .from-control {
  //       padding: 16px 16px 16px 16px;
  //       border-radius: 5px 5px 5px 5px;
  //       border-style: solid;
  //       border-width: 1px 1px 1px 1px;
  //       border-color: #f1f5fb;
  //       width: 100%;
  //       max-width: 100%;
  //     }
  //     textarea {
  //       height: 120px;
  //     }
  //     ::-webkit-input-placeholder {
  //       /* Chrome/Opera/Safari */
  //       color: #454545;
  //       opacity: 1;
  //     }
  //     ::-moz-placeholder {
  //       /* Firefox 19+ */
  //       color: #454545;
  //       opacity: 1;
  //     }
  //     :-ms-input-placeholder {
  //       /* IE 10+ */
  //       color: #454545;
  //       opacity: 1;
  //     }
  //     :-moz-placeholder {
  //       /* Firefox 18- */
  //       color: #454545;
  //       opacity: 1;
  //     }
  //   }
  // }

  .contact-widget {
    .row-control {
      padding-bottom: 20px;
    }
    .form-label {
      font-size: 16px;
      padding: 0;
    }
    .form-control {
      padding: 20px 20px 20px 20px;
      border-radius: 5px 5px 5px 5px;
      border-style: solid;
      border-width: 0px 0px 0px 0px;
      background-color: #4c77c71e;
      width: 100%;
      max-width: 100%;
      outline: none;
      &:active,
      &:focus {
        outline: none;
      }
    }
    .custom-input::placeholder {
      color: rgba(0, 0, 0, 0.411);
    }
    textarea {
      height: 140px;
      outline: none;
      &:active,
      &:focus {
        outline: none;
      }
    }
    .btn-secondary {
      -moz-user-select: none;
      background-color: #096bd8;
      border: medium none;
      display: inline-block;
      font-size: 14px;
      margin-bottom: 0;
      padding: 16px 60px;
      text-align: center;
      text-transform: uppercase;
      touch-action: manipulation;
      transition: all 0.3s ease 0s;
      vertical-align: middle;
      white-space: nowrap;
      border-radius: 5px;
      position: relative;
      font-family: "cerebri_sansbold";
      &:hover {
        color: #096bd8;
        background-color: #fff;
        border-color: #545b62;
        box-shadow: 0 0 0 0.2rem rgba(130,138,145,.5);
      }
    }
  
    
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #454545;
      opacity: 1;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: #454545;
      opacity: 1;
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      color: #454545;
      opacity: 1;
    }
    :-moz-placeholder {
      /* Firefox 18- */
      color: #454545;
      opacity: 1;
    }

  }
  .map-canvas {
    overflow: hidden;
    iframe {
      display: inherit;
      width: 100%;
      height: 620px;
      border: unset;
    }
  }
}

@media #{$lg} {
  .contact {
    .contact-box {
      padding: 70px 20px;
    }
  }
}
@media #{$sm} {
  .contact {
    .map-canvas {
      iframe {
        height: 500px;
      }
    }
  }
  .contact {
    &.style2 {
      .contact-box-wrap {
        padding: 70px 30px;
      }
    }
  }
}
@media #{$mobile} {
  .contact {
    .contact-box {
      padding: 50px 20px;
    }
    .map-canvas {
      iframe {
        height: 400px;
      }
    }
  }
}
