.banner  {
  .banner-height {
    max-height: 765;
  }  
  &.style4 {
    background: url(../../assets/img/banner/banner-1.jpg);
    background-size: cover;
    background-position: top right;
    background-repeat: no-repeat;
    // min-height: 100vh;
    // height: auto;
    justify-content: center;
    .banner-content {
      padding: 180px 0 85px;
      // padding: 10% 0 10%;
      max-width: 642px;
      .sub-title {
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 4px;
        color: $whiteColor;
        display: block;
        margin-bottom: 15px;
      }
      .title {
        font-size: 60px;
        line-height: 68px;
        font-weight: 700;
        color: $whiteColor;
        margin-bottom: 25px;
      }
      .desc {
        font-size: 20px;
        line-height: 34px;
        font-weight: 500;
        color: $whiteColor;
        margin-bottom: 55px;
      }
      .banner-btn {
        position: relative;
        li {
          display: inline-block;
          margin-right: 50px;
          .videos {
            .animate-border {
              a {
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      } 
    }
    .banner-content-right {
      :root {
        font-size: 20px;
        box-sizing: inherit;
      }
      
      *,
      *:before,
      *:after {
        box-sizing: inherit;
      }
      
      p {
        margin: 0;
      }
      
      p:not(:last-child) {
        margin-bottom: 1em;
      }
      .card-stack {
        position: relative;
        min-height: 100vh;
        height: 100%;
        padding: 1em;
      }
      
      .card {
        position: absolute;
        z-index: 4000;
        top: 30%;
        left: 28%;
        transform: translate(-50%, -50%);
      
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      
        width: 100%;
        max-width: 22em;
        height: 10em;
        padding: 0.3em;
      
        border: 1px solid hsl(0, 0%, 100%, 0.25);
        border-radius: .25em;
        box-shadow: 0 0 1em .5em hsl(0, 0%, 0%, 0.25);
        background: rgba(255, 255, 255, 0.507);
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.10) 0%, rgba(255,255,255,0.25) 100%);
        backdrop-filter: blur(.5em);
        .card-title {
          position: absolute;
          top: 50%;
          left: 1rem;
          font-size: 1.5rem;
          text-decoration: none;
          color: white;
        }
        .card-footer {
          padding-bottom: 0;
          padding-left: 0;
          font-size: 0.75em;
          color: white;
        }
        .card-icon {
          width: 50px;
          height: 40px;
          top: 0.3rem;
          left: 0.5rem;
          border-radius: 5px;
        }

        
        cursor: pointer;
      }

      .card:nth-child(2) {
        z-index: 5000;
        margin-top: 8em;
        margin-left: 6em;
        background: rgba(255, 255, 255, 0.507);
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.10) 0%, rgba(255,255,255,0.25) 100%);
        backdrop-filter: blur(.5em);
      }
      
      .card:nth-child(3) {
        z-index: 6000;
        margin-top: 16em;
        margin-left: 12em;
        background: rgba(255, 255, 255, 0.507);
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.10) 0%, rgba(255,255,255,0.25) 100%);
        backdrop-filter: blur(.75em);
      }
      .card:nth-child(4) {
        z-index: 7000;
        margin-top: 0;
        margin-left: 28em;
        background: rgba(255, 255, 255, 0.507);
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.10) 0%, rgba(255,255,255,0.25) 100%);
        backdrop-filter: blur(.75em);
      }
      .card:nth-child(5) {
        z-index: 8000;
        margin-top: 8em;
        margin-left: 34em;
        background: rgba(255, 255, 255, 0.507);
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.10) 0%, rgba(255,255,255,0.25) 100%);
        backdrop-filter: blur(.75em);
      }
      .card:nth-child(6) {
        z-index: 9000;
        margin-top: 16em;
        margin-left: 40em;
        background: rgba(255, 255, 255, 0.507);
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.10) 0%, rgba(255,255,255,0.25) 100%);
        backdrop-filter: blur(.75em);
      }
      
      .card.active {
        z-index: 9500;
        background: rgba(255, 255, 255, 0.507);
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.10) 0%, rgba(255,255,255,0.25) 100%);
        backdrop-filter: blur(.25em);
      }    
    }

    // Media query for landscape mode on small screens
    @media (max-width: 768px) and (orientation: landscape) {
      .banner-content {
        padding: 5% 0; // Reduce padding
        .title {
          font-size: 40px;
          line-height: 48px;
        }
        .desc {
          font-size: 16px;
          line-height: 28px;
        }
      }
      
      .banner-content-right {
        display: none;
      }
    }
  }




  &.style5 {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    .banner-content {
      padding: 100px 0 200px;
      .icon-seller {
        img {
          width: 80px;
        }
      }
      .title {
        font-size: 52px;
        line-height: 70px;
        font-weight: 700;
        color: $titleColor;
        span {
          color: #f24c1a;
        }
      }
      .desc {
        font-size: 20px;
        line-height: 30px;
        font-weight: 500;
        color: $bodyColor;
        margin-bottom: 40px;
        padding-right: 188px;
      }
    }
    .banner-img {
      .images-part {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5.6%;
        max-width: 40%;
        img {
          animation: move-y 2s alternate infinite;
        }
      }
      .layer-img {
        position: absolute;
        left: 58%;
        transform: translateX(-50%);
        bottom: 30px;
      }
    }
  }
}

@media #{$laptop} {
  .banner  {
    .banner-height {
      min-height: 700px;
    }  
    &.style4 {
      .banner-content {
        padding: 150px 0 66px;
        max-width: 540px;
        .sub-title {
          font-size: 12px;
          letter-spacing: 3px;
          margin-bottom: 13px;
        }
        .title {
          font-size: 52px;
          line-height: 64px;
          font-weight: 640;
          margin-bottom: 22px;
        }
        .desc {
          font-size: 18px;
          line-height: 30px;
        }
      }
      .banner-content-right {
        .card {
          z-index: 4000;
          top: 26%;
          left: 25%;
        
          width: 100%;
          max-width: 18em;
          height: 9em;
          padding: 0.3em;
        
          .card-title {
            top: 42%;
            left: 0.9rem;
            font-size: 1.3rem;
          }
          .card-footer {
            font-size: 0.7em;
          }
          .card-icon {
            width: 45px;
            height: 36px;
          }
        }
  
        .card:nth-child(2) {
          // z-index: 5000;
          margin-top: 8em;
          margin-left: 5em;
        }
        
        .card:nth-child(3) {
          // z-index: 6000;
          margin-top: 16em;
          margin-left: 10em;
        }
        .card:nth-child(4) {
          // z-index: 7000;
          margin-top: 0;
          margin-left: 20em;
        }
        .card:nth-child(5) {
          // z-index: 8000;
          margin-top: 8em;
          margin-left: 25em;
        }
        .card:nth-child(6) {
          // z-index: 9000;
          margin-top: 16em;
          margin-left: 30em;
        }
      }
    }
    &.style5 {
      .banner-content {
        .title {
          font-size: 48px;
          line-height: 1.5;
        }
      }
    }
  }
}
@media #{$xl} {
  .banner  {
    .banner-height {
      // min-height: 660px;
      max-height: 600px;
    }  
    &.style4 {
      .banner-content {
        padding: 130px 0 60px;
        max-width: 520px;
        .sub-title {
          // font-size: 12px;
          letter-spacing: 3px;
          margin-bottom: 13px;
        }
        .title {
          font-size: 44px;
          line-height: 54px;
          font-weight: 620;
          margin-bottom: 20px;
        }
        .desc {
          font-size: 16px;
          line-height: 26px;
        }
      }
      .banner-content-right {
        .card {
          // z-index: 4000;
          top: 24%;
          left: 20%;
        
          max-width: 15em;
          height: 8em;
          padding: 0.3em;
        
          .card-title {
            // top: 42%;
            left: 0.8rem;
            font-size: 1.2rem;
          }
          // .card-footer {
          //   font-size: 0.7em;
          // }
          .card-icon {
            width: 40px;
            height: 32px;
          }
        }
  
        .card:nth-child(2) {
          // z-index: 5000;
          margin-top: 7em;
          margin-left: 5em;
        }
        
        .card:nth-child(3) {
          // z-index: 6000;
          margin-top: 14em;
          margin-left: 10em;
        }
        .card:nth-child(4) {
          // z-index: 7000;
          margin-top: 0;
          margin-left: 19em;
        }
        .card:nth-child(5) {
          // z-index: 8000;
          margin-top: 7em;
          margin-left: 23em;
        }
        .card:nth-child(6) {
          // z-index: 9000;
          margin-top: 14em;
          margin-left: 27em;
        }
      }
    }
    &.style5 {
      .banner-content {
        .title {
          font-size: 45px;
        }
      }
    }
  }
}
@media #{$lg} {
  .banner  {
    .banner-height {
      min-height: 660px;
    }  
    &.style4 {
      background-position: center;
      .banner-content {
        padding: 130px 0 40px;
        max-width: 500px;
        .sub-title {
          // font-size: 12px;
          letter-spacing: 3px;
          margin-bottom: 13px;
        }
        .title {
          font-size: 42px;
          line-height: 50px;
          font-weight: 600;
          margin-bottom: 18px;
        }
      }
      .banner-content-right {
        .card {
          // z-index: 4000;
          top: 24%;
          left: 10%;
          margin-left: 8em;
        
          max-width: 14em;
          height: 8em;
          padding: 0.2em;
        
          .card-title {
            // top: 42%;
            left: 0.6rem;
            font-size: 1.1rem;
          }
          // .card-footer {
          //   font-size: 0.7em;
          // }
          .card-icon {
            width: 40px;
            height: 32px;
          }
        }
  
        .card:nth-child(2) {
          // z-index: 5000;
          margin-top: 8em;
          margin-left: 8em;
        }
        .card:nth-child(3) {
          // z-index: 6000;
          margin-top: 16em;
          margin-left: 8em;
        }
        .card:nth-child(4) {
          // z-index: 7000;
          margin-top: 0;
          margin-left: 20em;
        }
        .card:nth-child(5) {
          // z-index: 8000;
          margin-top: 8em;
          margin-left: 20em;
        }
        .card:nth-child(6) {
          // z-index: 9000;
          margin-top: 16em;
          margin-left: 20em;
        }
      }
    }
    &.style5 {
      .banner-content {
        padding: 180px 0 200px;
        .title {
          font-size: 40px;
        }
      }
    }
  }
}

@media #{$md} {
  .banner  {
    .banner-height {
      min-height: 660px;
    }  
    &.style4 {
      background-position: center;
      .banner-content {
        padding: 130px 0 40px;
        max-width: 500px;
        .sub-title {
          // font-size: 12px;
          letter-spacing: 3px;
          margin-bottom: 13px;
        }
        .title {
          font-size: 40px;
          line-height: 48px;
          font-weight: 600;
          margin-bottom: 18px;
        }
      }
      .banner-content-right {
        .card {
          // z-index: 4000;
          top: 16%;
          left: 12%;
          margin-left: 8em;
        
          max-width: 14em;
          height: 7em;
          padding: 0.2em;
        
          .card-title {
            // top: 42%;
            left: 0.6rem;
            font-size: 1rem;
          }
          // .card-footer {
          //   font-size: 0.7em;
          // }
          .card-icon {
            width: 36px;
            height: 30px;
          }
        }
  
        .card:nth-child(2) {
          // z-index: 5000;
          margin-top: 8em;
          margin-left: 8em;
        }
        .card:nth-child(3) {
          // z-index: 6000;
          margin-top: 16em;
          margin-left: 8em;
        }
        .card:nth-child(4) {
          // z-index: 7000;
          margin-top: 24em;
          margin-left: 8em;
        }
        .card:nth-child(5) {
          // z-index: 8000;
          margin-top: 32em;
          margin-left: 8em;
        }
        .card:nth-child(6) {
          // z-index: 9000;
          margin-top: 40em;
          margin-left: 8em;
        }
      }
    }
    &.style5 {
      .banner-content {
        .title {
          font-size: 34px;
        }
      }
    }
  }
}

@media #{$sm} {
  .banner  {
    &.style4 {
      background-position: center;
      .banner-content {
        padding: 120px 0 40px;
        max-width: 400px;
        .sub-title {
          // font-size: 12px;
          letter-spacing: 3px;
          margin-bottom: 13px;
        }
        .title {
          font-size: 38px;
          line-height: 46px;
          font-weight: 550;
          // margin-bottom: 18px;
        }
      }
      .banner-content-right {
        .card {
          // z-index: 4000;
          top: 16%;
          left: 10%;
          margin-left: 7em;
        
          // max-width: 14em;
          // height: 7em;
          // padding: 0.2em;
        
          .card-title {
            // top: 42%;
            // left: 0.6rem;
            // font-size: 1rem;
          }
          // .card-footer {
          //   font-size: 0.7em;
          // }
          .card-icon {
            width: 34px;
            height: 28px;
          }
        }
  
        .card:nth-child(2) {
          margin-left: 7em;
        }
        .card:nth-child(3) {
          margin-left: 7em;
        }
        .card:nth-child(4) {
          margin-left: 7em;
        }
        .card:nth-child(5) {
          margin-left: 7em;
        }
        .card:nth-child(6) {
          margin-left: 7em;
        }
      }
    }
    &.style5 {
      .banner-content {
        padding: 120px 0 60px;
        .title {
          font-size: 30px;
        }
      }
      .banner-img {
        .layer-img {
          bottom: 20px;
          max-width: 100px;
        }
      }
    }
  }
}

@media #{$xs} {
  .banner  {
    &.style4 {
      .banner-content {
        padding: 120px 30px 140px;
        .sub-title {
          // font-size: 12px;
          letter-spacing: 3px;
          margin-bottom: 13px;
        }
        .title {
          font-size: 38px;
          line-height: 46px;
          font-weight: 550;
          // margin-bottom: 18px;
        }
        .desc {
          font-size: 16px;
          line-height: 26px;
        }
      }
      .banner-content-right {
        display: none;
      }
    }
    &.style5 {
      .banner-content {
        .title {
          font-size: 28px;
        }
        .desc {
          padding-right: 100px;
        }
      }
    }
  }
}

@media #{$mobile} {
  .banner  {
    &.style4 {
      .banner-content {
        .title {
          font-size: 30px;
          line-height: 40px;
        }
        .banner-btn {
          li {
            margin-right: 30px;
          }
        }
      }
    }
    &.style5 {
      .banner-content {
        .title {
          font-size: 22px;
          margin-bottom: 10px;
        }
        .desc {
          font-size: 18px;
          padding-right: 0;
        }
      }
      .banner-img .layer-img {
        max-width: 85px;
        left: 65%;
      }
    }
  }
}
