    /*
    Flaticon icon font: Flaticon
    Creation date: 10/09/2019 11:56
    */

    @font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

    .fi:before{
        display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
    }

    .flaticon-best:before { content: "\f100"; }
.flaticon-flower:before { content: "\f101"; }
.flaticon-timer:before { content: "\f102"; }
.flaticon-suitcase:before { content: "\f103"; }
.flaticon-chess-piece:before { content: "\f104"; }
.flaticon-clock:before { content: "\f105"; }
.flaticon-time-call:before { content: "\f106"; }
.flaticon-phone:before { content: "\f107"; }
.flaticon-call:before { content: "\f108"; }
.flaticon-email:before { content: "\f109"; }
.flaticon-location:before { content: "\f10a"; }
.flaticon-send:before { content: "\f10b"; }
.flaticon-bag:before { content: "\f10c"; }
.flaticon-supermarket:before { content: "\f10d"; }
.flaticon-basket:before { content: "\f10e"; }
.flaticon-shopping-bag:before { content: "\f10f"; }
.flaticon-right-arrow:before { content: "\f110"; }
.flaticon-left-arrow:before { content: "\f111"; }
.flaticon-back:before { content: "\f112"; }
.flaticon-right-arrow-1:before { content: "\f113"; }
.flaticon-next:before { content: "\f114"; }
.flaticon-back-1:before { content: "\f115"; }
.flaticon-upload:before { content: "\f116"; }
.flaticon-next-1:before { content: "\f117"; }
.flaticon-ui:before { content: "\f118"; }
.flaticon-next-2:before { content: "\f119"; }
.flaticon-back-2:before { content: "\f11a"; }
.flaticon-play-button:before { content: "\f11b"; }
.flaticon-play-button-1:before { content: "\f11c"; }
.flaticon-picture:before { content: "\f11d"; }
.flaticon-image:before { content: "\f11e"; }
.flaticon-gallery:before { content: "\f11f"; }
.flaticon-pictures:before { content: "\f120"; }
.flaticon-unlink:before { content: "\f121"; }
.flaticon-link:before { content: "\f122"; }
.flaticon-link-1:before { content: "\f123"; }
.flaticon-link-2:before { content: "\f124"; }
.flaticon-speaker:before { content: "\f125"; }
.flaticon-speaker-1:before { content: "\f126"; }
.flaticon-picture-1:before { content: "\f127"; }
.flaticon-picture-2:before { content: "\f128"; }
.flaticon-right-quote:before { content: "\f129"; }
.flaticon-error:before { content: "\f12a"; }
.flaticon-cross:before { content: "\f12b"; }
.flaticon-search:before { content: "\f12c"; }
.flaticon-shopping-bag-1:before { content: "\f12d"; }
.flaticon-eye:before { content: "\f12e"; }
.flaticon-user:before { content: "\f12f"; }
.flaticon-user-1:before { content: "\f130"; }
.flaticon-clock-1:before { content: "\f131"; }
.flaticon-comment:before { content: "\f132"; }
.flaticon-folder:before { content: "\f133"; }
.flaticon-price-tag:before { content: "\f134"; }
    
    $font-Flaticon-best: "\f100";
    $font-Flaticon-flower: "\f101";
    $font-Flaticon-timer: "\f102";
    $font-Flaticon-suitcase: "\f103";
    $font-Flaticon-chess-piece: "\f104";
    $font-Flaticon-clock: "\f105";
    $font-Flaticon-time-call: "\f106";
    $font-Flaticon-phone: "\f107";
    $font-Flaticon-call: "\f108";
    $font-Flaticon-email: "\f109";
    $font-Flaticon-location: "\f10a";
    $font-Flaticon-send: "\f10b";
    $font-Flaticon-bag: "\f10c";
    $font-Flaticon-supermarket: "\f10d";
    $font-Flaticon-basket: "\f10e";
    $font-Flaticon-shopping-bag: "\f10f";
    $font-Flaticon-right-arrow: "\f110";
    $font-Flaticon-left-arrow: "\f111";
    $font-Flaticon-back: "\f112";
    $font-Flaticon-right-arrow-1: "\f113";
    $font-Flaticon-next: "\f114";
    $font-Flaticon-back-1: "\f115";
    $font-Flaticon-upload: "\f116";
    $font-Flaticon-next-1: "\f117";
    $font-Flaticon-ui: "\f118";
    $font-Flaticon-next-2: "\f119";
    $font-Flaticon-back-2: "\f11a";
    $font-Flaticon-play-button: "\f11b";
    $font-Flaticon-play-button-1: "\f11c";
    $font-Flaticon-picture: "\f11d";
    $font-Flaticon-image: "\f11e";
    $font-Flaticon-gallery: "\f11f";
    $font-Flaticon-pictures: "\f120";
    $font-Flaticon-unlink: "\f121";
    $font-Flaticon-link: "\f122";
    $font-Flaticon-link-1: "\f123";
    $font-Flaticon-link-2: "\f124";
    $font-Flaticon-speaker: "\f125";
    $font-Flaticon-speaker-1: "\f126";
    $font-Flaticon-picture-1: "\f127";
    $font-Flaticon-picture-2: "\f128";
    $font-Flaticon-right-quote: "\f129";
    $font-Flaticon-error: "\f12a";
    $font-Flaticon-cross: "\f12b";
    $font-Flaticon-search: "\f12c";
    $font-Flaticon-shopping-bag-1: "\f12d";
    $font-Flaticon-eye: "\f12e";
    $font-Flaticon-user: "\f12f";
    $font-Flaticon-user-1: "\f130";
    $font-Flaticon-clock-1: "\f131";
    $font-Flaticon-comment: "\f132";
    $font-Flaticon-folder: "\f133";
    $font-Flaticon-price-tag: "\f134";